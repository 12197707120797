<template>
    <b-container fluid id="certificates" class="mb-5">
        <b-row>
            <b-col cols="12" lg="8" offset-lg="2" class="my-5">
                <h2>{{ presentationText.certifikatNaslov }}</h2>
                <br><br>
                <p>{{ presentationText.sestiOdstavek }}</p>
                <p>{{ presentationText.sedmiOdstavek }}</p>
                <p>{{ presentationText.osmiOdstavek }}</p>
            </b-col>
        </b-row>
        <b-row align-v="center" align-h="center">
            <b-col cols="12" lg="3" class="presentationImg">
                <b-img
                :src="require('../../assets/Certifikat3.png')"
                class="presentationImg" alt="iiw certificate"
                ></b-img>
            </b-col>
            <b-col cols="12" md="6" lg="3" class="presentationImg my-5">
                <b-img
                :src="require('../../assets/Certifikat1.png')"
                class="presentationImg biggerCert" alt="VARcert certificate"
                ></b-img>
            </b-col>
            <b-col cols="12" md="6" lg="3" class="presentationImg my-5">
                <b-img
                :src="require('../../assets/Certifikat4.png')"
                class="presentationImg biggerCert" alt="VARcert certificate"
                ></b-img>
            </b-col>
            <b-col cols="12" lg="3" class="presentationImg">
                <b-img
                :src="require('../../assets/Certifikat2.png')"
                class="presentationImg" alt="iiw certificate"
                ></b-img>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'PresentationCertificates',
  props: {
      presentationText: Object
  }
}
</script>

<style>
#certificates {
    min-height: 60vh;
    background-color: #3d3d3d;
    z-index: 200;
    border-bottom: 8px solid #0055a6;
    padding-bottom: 2%;
}
.biggerCert {
  width: 70%;
}
</style>