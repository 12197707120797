import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/machinery',
    name: 'Machinery',
    component: () => import('../views/Machinery.vue')
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('../views/Gallery.vue')
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import('../views/admin/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/images',
    name: 'Images',
    component: () => import('../views/admin/Images.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound,
    meta: { hideNav: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/' || 'https://sads.si/',
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = store.getters.loggedIn;

  if (requiresAuth && !currentUser) next('/admin/login');
  else next();
});

export default router