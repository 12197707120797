<template>
  <b-col cols="12" lg="4" class="description" id="contact-info">
    <h3>{{ headerContact }}</h3>
    <b-container class="my-5 text-left">
        <b-row>
            <b-col>
              <b-row>
                <b-col cols="1" class="my-auto mr-2"><i class="fas fa-map-marker-alt"></i></b-col>
                <b-col cols="10">
                  <b-row><a href="https://www.google.com/maps/place/Brezni+Vrh+38a,+2363+Podvelka/@46.6193713,15.3240331,17z/data=!3m1!4b1!4m5!3m4!1s0x476f917189647fb9:0xe4eb26c1b9a7fb2!8m2!3d46.6193676!4d15.3262218" target="_blank"><b>{{ headquarters }} </b> Brezni vrh 38a, 2363 Podvelka</a></b-row>
                  <b-row><a href="https://www.google.com/maps/place/Mariborska+cesta+38,+2360+Radlje+ob+Dravi/@46.6135716,15.2259358,17z/data=!3m1!4b1!4m5!3m4!1s0x476f92e8b0da755b:0x7c7f889784da2342!8m2!3d46.6135679!4d15.2281245" target="_blank"><b>{{ branch }} </b> Mariborska cesta 38, 2360 Radlje ob Dravi</a></b-row>
                </b-col>
              </b-row>
            </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col cols="1" class="my-auto mr-2"><i class="fas fa-phone"></i></b-col>
            <b-col cols="10">
                  <b-row><a href="tel:0038640876690">Sergej: 00386 40 876 690</a></b-row>
                  <b-row><a href="tel:0038640711075">Dušan: 00386 40 711 075</a></b-row>
                </b-col>
        </b-row>
        <br>
        <b-row>
            <b-col cols="1"><i class="far fa-envelope"></i></b-col>
            <b-col cols="10"><a href="mailto:info@sads.si">info@sads.si</a></b-col>
        </b-row>
    </b-container>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactInfo',
  data() {
    return {
      headerContact: String,
      headquarters: String,
      branch: String
    }
  },
  created() {
    this.getHeader()
  },
  computed: mapState(['language']),
  watch: {
    language(val) {
      this.getHeader()
    }
  },
  methods: {
    getHeader() {
      this.headerContact = this.$store.getters.getDescription.contact.title
      this.headquarters = this.$store.getters.getDescription.contact.headquarters
      this.branch = this.$store.getters.getDescription.contact.branch
    }
  }
}
</script>

<style>
#contact-info{
    padding: 2%;
    border-right: 8px solid #0055a6;
    border-top: 8px solid #0055a6;
    border-left: 0;
    line-height: 150%;
}
a {
  color: white !important;
}
</style>