<template>
    <b-row class="presentation-container">
      <b-col cols="12" class="presentation">
          <h2 class="mb-5"><router-link id="color-style" to="/machinery">{{ presentationText.strojiNaslov }}</router-link></h2>
          <b-row>
              <b-col cols="12" lg="6" class="presentationImg">
                  <b-img-lazy
                    v-bind="mainProps"
                    :src="require('../../assets/Picture8.jpg')"
                    class="presentationImg" alt="Turning machine"
                  ></b-img-lazy>
              </b-col>
              <b-col cols="12" lg="4" offset-lg="1" class="pres-text">
                  <p>{{ presentationText.devetiOdstavek }}</p>
              </b-col>
          </b-row>
      </b-col>
      <b-col cols="12" id="presentation-alt" class="presentation">
          <b-row>
              <b-col cols="12" lg="6" offset-lg="1" class="my-auto">
                  <p>{{ presentationText.petiOdstavek }}</p>
                  <p>{{ presentationText.tretjiOdstavek }}</p>
                  <br><br>
                  <p class="important-text">{{ presentationText.cetrtiOdstavek }}</p>
                  <br>
                  <p><router-link id="color-style" to="/gallery">{{ productsLink }}</router-link></p>
              </b-col>
              <b-col cols="12" lg="4" class="presentationImg">
                  <b-img-lazy
                    v-bind="mainProps"
                    :src="require('../../assets/Picture2.jpg')"
                    class="presentationImg" alt="welding picture"
                  ></b-img-lazy>
              </b-col>
          </b-row>
      </b-col>
      <Certificates :presentationText="presentationText" />
      <FinanciraEu :presentationText="presentationText" />
    </b-row>
</template>

<script>
import { mapState } from 'vuex';
import Certificates from './PresentationCertificates'
import FinanciraEu from './FinanciraEu'

export default {
  name: 'Presentation',
  components: {
      Certificates, FinanciraEu
  },
  data () {
      return {
          mainProps: {
            center: true,
            blank: true,
            blankColor: '#3d3d3d'
          },
          presentationText: {},
          productsLink: 'Oglejte si naše produkte'
      }
  },
  created() {
    this.getPresentation()
  },
  computed: mapState(['language']),
  watch: {
    language(val) {
      this.getPresentation()
    }
  },
  methods: {
    getPresentation() {
      this.presentationText = this.$store.getters.getDescription
    },
    changeLang(lang) {
      if (lang === 'SLO') {
        this.productsLink = 'Oglejte si naše produkte'
      } else if (lang === 'ENG') {
        this.productsLink = 'Take a look at our products'
      } else {
        this.productsLink = 'Schauen Sie sich unsere Produkte an'
      }
    }
  }
}
</script>

<style>
.presentation-container{
  margin-top: 90vh;
  z-index: 200;
}
.presentationImg{
  padding: 0;
  max-width: 100%;
}
.presentation{
  background-color: #3d3d3d;
  color: white;
  padding: 3% 3%;
  font-size: 1.2rem;
  line-height: 200%;
  border-top: 8px solid #0055a6;
}
#presentation-alt{
  background-color: #636363;
  border-top: none;
}
.pres-text{
  margin-top: 5%;
}
.important-text{
  font-size: 1.3rem;
  font-weight: 600;
}
#color-style{
  color: white !important;
  font-weight: 500 !important;
}
@media only screen and (max-width: 1200px) {
    .presentation{
      font-size: 1rem;
      line-height: 100%;
      padding: 3% 5%;
    }
    .presentation-container{
      margin-top: 20vh;
    }
    #presentation-alt{
      padding-top: 15%;
    }
}
</style>