<template>
  <div id="app">
    <Navbar v-if="!$route.meta.hideNav" />
    <router-view class="main-view"/>
  </div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue'
export default {
  name: 'App',
  components: {
    Navbar
  },
  data() {
    return {
      logo: 'https://' + process.env.VUE_APP_FIREBASE_PROJECT_ID + '.com/' + require('./assets/logo.png')
    }
  },
  metaInfo() {
    return {
      title: "SADS d.o.o. | Rezkanje, Struženje, Varjenje, Brušenje",
      link: [
        { rel:'icon', type: 'image/x-icon', href: '../public/favicon.ico' },
        { rel:'shortcut icon', type: 'image/x-icon', href: '../public/favicon.ico' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-57x57.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-72x72.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-76x76.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-114x114.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-120x120.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-144x144.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-152x152.png' },
        { rel:'apple-touch-icon', href: '../public/apple-touch-icon-180x180.png' }
      ],
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'language', content: 'sl' },
        { name: 'http-equiv="X-UA-Compatible"', content: 'IE=edge' },
        { name: 'keywords', content: 'SADS, varjenje, brušenje, barvanje, rezkanje, struženje, rezkalni stroj, stružnica, hrizontalno vrtalno rezkalni stroj' },
        { name: 'description', content: `Podjetje SADS d.o.o. se ukvarja z varjenjem, brušenjem, barvanjem, rezkanjem in struženjem.` },
        { name: 'robots', content:'index,follow' },
        //facebook OpenGraph
        { property: 'og:title', content:'SADS d.o.o.' },
        { property: 'og:type', content:'website' },
        { property: 'og:description', content:`Podjetje SADS d.o.o. se ukvarja z varjenjem, brušenjem, barvanjem, rezkanjem in struženjem. Trenutno imamo 6 hrizontalno vrtalno rezkalnih strojev, 2 rezkalna stroja in 2 stružnici.` },
        { property: 'og:image', content: this.logo },
        //twitter card
        { property: 'twitter:title', content:'SADS d.o.o.' },
        { property: 'twitter:card', content:'summary' },
        { property: 'twitter:description', content:`Podjetje SADS d.o.o. se ukvarja z varjenjem, brušenjem, barvanjem, rezkanjem in struženjem. Trenutno imamo 6 hrizontalno vrtalno rezkalnih strojev, 2 rezkalna stroja in 2 stružnici.` },
        { property: 'twitter:image', content: this.logo },
      ],
      script: [
        {
          src: 'https://www.gstatic.com/firebasejs/7.18.0/firebase-app.js'
        }
      ]
    }
  }
}
</script>

<style>
html{
  background-color: #4a4a4a;
}
#app {
  font-family: "Century Gothic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: #4a4a4a;
  overflow-x: hidden;
}
.main-view {
  margin-top: 5vh;
}
.background {
  padding: 0 15px !important;
}
</style>
