<template>
  <b-container fluid class="background">
    <b-row><img id="back-img" src="../assets/carbide-drill-bit.jpg" alt="carbide drill background"></b-row>
    <b-row><img id="back-img-mobile" src="../assets/carbide-drill-bit-mobile.jpg" alt="carbide drill background mobile"></b-row>
    <b-row id="banner">
      <Carousel />
      <Description />
    </b-row>
    <Presentation />
    <b-row class="mt-5">
      <ContactInfo />
      <Map />
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/content/Carousel.vue'
import Description from '@/components/content/Description.vue'
import Map from '@/components/content/Map.vue'
import ContactInfo from '@/components/content/ContactInfo.vue'
import Presentation from '@/components/content/Presentation.vue'

export default {
  name: 'Home',
  components: {
    Carousel,
    Description,
    Map,
    ContactInfo,
    Presentation
  }
}
</script>

<style>
#back-img {
  opacity: 0.5;
  position: fixed;
  width: 100vw;
  top: 5%;
}
#back-img-mobile{
  visibility: hidden;
  opacity: 0.5;
  position: fixed;
  width: 100vw;
}
.background{
  padding: 0;
}
#banner{
  background-color: #4a4a4a;
  padding: 5% 0;
  position: relative;
  z-index: 200;
  border-bottom: 8px solid #0055a6;
}
.description{
  background-color: #3d3d3d;
  color: white;
  padding: 5% 3%;
  font-size: 1.1rem;
  line-height: 200%;
  border-left: 8px solid #0055a6;
  z-index: 200;
}
h2 {
  text-transform: uppercase;
}
@media only screen and (max-width: 1200px) {
    .description{
      font-size: 1rem;
      line-height: 100%;
      font-weight: 300;
      padding: 5% 5%;
    }
    #back-img {
      visibility: hidden;
    }
    #back-img-mobile{
      visibility: visible;
    }
    #banner{
      padding-bottom: 0;
    }
}
</style>