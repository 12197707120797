<template>
  <b-container fluid class="error-page">
    <h1>404 Not Found!</h1>
    <img id="error-logo" src="../assets/logo-sads.png">
    <h3><a class="link-home" href="/">Home</a></h3>
  </b-container>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>
.error-page {
    margin-top: 5%;
}
#error-logo {
    width: 100%;
    margin: 3% 0;
}
.link-home {
    color: white;
    font-size: 1.1em;
}
</style>