<template>
    <b-col cols="12" class="presentation">
      <div class="container text-left">
        <h3 class="text-center">{{ presentationText.financiraEU.naslov }}</h3>
        <p class="text-center mb-3">{{ presentationText.financiraEU.podnaslov }}</p>
        <p>
          <strong class="h4">{{ presentationText.financiraEU.namen }}</strong>
          <br>
          {{ presentationText.financiraEU.namenOpis }}
        </p>
        <b-row class="mt-5">
          <b-col cols="12" md="6">
            <strong class="h4">{{ presentationText.financiraEU.rezultatiText }}</strong>
            <ul
              v-for="(line, key) in presentationText.financiraEU.rezultati"
              :key="key"
            >
              <li>{{ line }}</li>
            </ul>
          </b-col>
          <b-col cols="12" md="6">
            <p><strong class="">{{ presentationText.financiraEU.zakljucek }} </strong>22.2.2023</p>
            <p><strong class="">{{ presentationText.financiraEU.visinaNalozbe }} </strong>{{ presentationText.financiraEU.visinaNalozbeVsota }}</p>
            <p><strong class="">{{ presentationText.financiraEU.VisinaFin }} </strong>190.600 EUR</p>
            <p>{{ presentationText.financiraEU.nalozbaJeDel }} <a href="https://www.gov.si/zbirke/projekti-in-programi/nacrt-za-okrevanje-in-odpornost" target="_blank" rel="noopener noreferrer">noo.gov.si</a></p>
        </b-col>
        </b-row>
        <b-img-lazy
          v-bind="mainProps"
          fluid
          :src="require('../../assets/financira-eu.jpg')"
          class="financira-eu-img mt-5" alt="Financira EU"
        ></b-img-lazy>
        <p class="text-center mt-1">{{ presentationText.financiraEU.financira }}</p>
      </div>
    </b-col>
</template>

<script>
export default {
  name: 'PresentationCertificates',
  props: {
      presentationText: Object
  },
  data () {
    return {
      mainProps: {
        center: true,
        blank: true,
        blankColor: '#3d3d3d'
      },
    }
  }
}
</script>

<style>
.financira-eu-img {
  width: 50%;
}
</style>
