<template>
  <b-col id="box-map" cols="12" lg="8" >
    <GmapMap
      v-bind:center="center"
      :zoom="8"
      id="map"
    >
      <GmapMarker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center=m.position"
      />
    </GmapMap>
  </b-col>
</template>

<script>
export default {
  name: 'Map',
  data() {
    return {
      center: {lat: 46.6144, lng: 15.2255},
      markers: [
        {
          position: {lat: 46.613615, lng: 15.228137}
        },
        {
          position: {lat: 46.619377, lng: 15.326179}
        }
      ]
    }
  }
}
</script>

<style>
#map {
  height: 100%;
}
#box-map {
  padding: 0;
  border-top: 8px solid #0055a6;
  z-index: 200;
}
@media only screen and (max-width: 1200px) {
    #map {
      height: 40vh;
    }
}
</style>