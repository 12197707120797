<template>
    <div>
        <b-navbar toggleable="md" type="dark" id="nav-all" fixed="top" class="py-2">
            <b-navbar-brand to="/" class="col-2">
                <img id="nav-title" src="../../assets/logo-sads.png" alt="SADS logo">
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item to="/" class="nav-link">{{ selectedLang.home }}</b-nav-item>
                    <b-nav-item to="/machinery" class="nav-link">{{ selectedLang.contacts }}</b-nav-item>
                    <b-nav-item to="/gallery" class="nav-link">{{ selectedLang.gallery }}</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            <b-nav-item-dropdown id="drop-down" class="list-unstyled mx-3">
                <template v-slot:button-content>
                    <img :src="require('../../assets/' + selectedImg)" alt="language flag"> {{ language }}
                </template>
                <b-dropdown-item @click="changeLanguage('SLO')"><img src="../../assets/si.png" alt="Slovenian flag"> SLO</b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('ENG')"><img src="../../assets/gb.png" alt="English flag"> ENG</b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('GER')"><img src="../../assets/de.png" alt="German flag"> GER</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
        return {
            language: "",
            selectedImg: "",
            selectedLang: {
                home: "Domov",
                gallery: "Produkti",
                contacts: "Strojni park"
            }
        }
    },
    created() {
        let lang = this.$store.getters.getLanguage
        this.changeLanguage(lang)
    },
    methods: {
        changeLanguage(langChange) {
            if(langChange === "SLO") {
                this.selectedImg = "si.png"
                this.selectedLang.home = "Domov"
                this.selectedLang.gallery = "Produkti"
                this.selectedLang.contacts = "Strojni park"
            } else if(langChange === "ENG") {
                this.selectedImg = "gb.png"
                this.selectedLang.home = "Home"
                this.selectedLang.gallery = "Products"
                this.selectedLang.contacts = "Machinery"
            } else {
                this.selectedImg = "de.png"
                this.selectedLang.home = "Startseite"
                this.selectedLang.gallery = "Produkte"
                this.selectedLang.contacts = "Maschinenpark"
            }
            this.language = langChange
            this.$store.commit("changeLanguage", langChange)
        }
    }
}
</script>

<style>
#nav-all {
    background-color: #0055a6;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 0;
}
#nav-title {
    width: 80%;
}
#nav-title:hover {
    cursor: pointer;
}
.nav-link {
    color: #999b9e !important;
    font-weight: 600;
    font-size: 1.5rem;
    outline:none;
}
.nav-link:hover {
    color: white !important;
}
.nav-link a.router-link-exact-active {
    color: white !important;
}

#drop-down__BV_button_ {
    font-size: 1.1rem;
    outline:none;
}
.dropdown-menu {
    width: fit-content;
    background-color: #0055a6 !important;
}
a.dropdown-item {
    color: white !important;
    outline:none;
}
a.dropdown-item:hover {
    background-color: #3d3d3d !important;
}
button:focus {
    outline: none !important;
}

@media only screen and (max-width: 800px) {
    #nav-title {
        width: 250%
    }
    .nav-link {
        font-size: 1.2rem;
    }
    .collapsed{
        margin-left: 15%;
    }
}
</style>
