import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    error: null,
    language: 'SLO',
    description: {
      slovenian: {
        contact: {
          title: 'Kontakt',
          headquarters: 'Sedež: ',
          branch: 'Poslovna enota: '
        },
        certifikatNaslov: `Naši certifikati`,
        strojiNaslov: `Naši stroji`,
        prviOdstavek: `Družinsko podjetje SADS D.O.O. se nahaja v obrtni coni v Radljah ob Dravi. Inovacije in kakovost so gonilna sila naših dejavnosti in so del vseh procesov v našem podjetju. `,
        drugiOdstavek: `Ukvarjamo se z varjenjem in mehansko obdelavo kovin. Smo natančni, inovativni ter prilagodljivi strankam, naše vodilo pa je kakovostna izdelava.`,
        tretjiOdstavek: `Ponosni smo na našo izkušeno ekipo in stroje, ki zagotavljajo izdelavo in predelavo varjenih strojnih delov.`,
        cetrtiOdstavek: `Nudimo varjenje, brušenje, barvanje, rezkanje in struženje. `,
        petiOdstavek: `V našem podjetju Izdelujemo različne strojne dele, pri katerih sta zelo pomembna kakovostno varjenje in mehanska obdelava kovin. `,
        sestiOdstavek: `Varimo različne materiale z različnimi postopki varjenja. Pri izvajanju varilnih del izvedemo začetni, vmesni in končni NDT pregled.`,
        sedmiOdstavek: `Za zagotavljanje kakovosti preverjamo zvare, nadzorujemo dimenzije (toleranco) in nadzorujemo kakovost površine.`,
        osmiOdstavek: `Zagotavljamo največjo možno združljivost in zanesljivost pri uporabi izdelkov v najzahtevnejših delovnih pogojih. `,
        devetiOdstavek: `Trenutno imamo 6 horizontalno vrtalno rezkalnih strojev, 2 rezkalna stroja in 2 stružnici. Na našem največjem CNC horizontalno vrtalno rezkalnem stroju (x:4000, y:2500) lahko obdelujemo do 6 ton težke obdelovance. Stružimo dele do 780mm premera in 3000mm dolžine.`,
        dimenzije: {
          bohrwerk: ['Dimenzije mize : 2000 × 2000', 'X : 4000, Y : 2800, Z : 4000', 'B : 360 stopinj', 'Vreteno : Ø 130', 'Vpenjanje ISO : 50', 'Največja obremenitev : 50 ton'],
          struznica: ['Premer : 780 mm', 'Stružna dolžina : 3000 mm', 'Premer struženja : 500 mm', 'Premer vretena: 100 mm']
        },
        financiraEU: {
          naslov: 'Projekt: Naložba v visokotehnološko CNC opremo za oblikovanje kovin',
          podnaslov: 'JR P4I 2022 - Spodbude za sofinanciranje začetnih investicij na manj razvitih območjih',
          namen: 'Namen projekta:',
          namenOpis: 'Predmet naložbe je  nakup CNC stroja za oblikovanje kovin VF-4SS in  CNC stružnice ST-30. Naložba bo omogočila večjo snovno učinkovitost, zmanjšanje porabe energije ter zmanjšanje odpadkov v proizvodnji.',
          rezultatiText: 'Načrtovani rezultati:',
          rezultati: ['izboljšati snovno učinkovitost proizvodnje', 'zmanjšanje porabe energije v proizvodnji', 'zmanjšanje odpadkov v proizvodnji', 'povečanje dodane vrednosti na zaposlenega', '1 nova zaposlitev v podjetju', 'izboljšanje kakovosti izdelkov'],
          zakljucek: 'Zaključek projekta:',
          visinaNalozbe: 'Višina naložbe:',
          visinaNalozbeVsota: '190.600 EUR brez DDV',
          VisinaFin: 'Višina financiranja iz mehanizma:',
          nalozbaJeDel: 'Naložba je del načrta, ki se financira iz mehanizma',
          financira: 'Financira Evropska unija – NextGenerationEU'
        }
      },
      english: {
        contact: {
          title: 'Contact',
          headquarters: 'Headquarters: ',
          branch: 'Branch: '
        },
        certifikatNaslov: `Our certificates`,
        strojiNaslov: `Our machines`,
        prviOdstavek: `The family business SADS D.O.O. is located in the craft zone in Radlje ob Dravi. Innovation and quality are the driving force of our activities and are part of all processes in our company.`,
        drugiOdstavek: `We are engaged in welding and mechanical processing of metals. We are precise, innovative and adaptable to customers, and our guideline is quality workmanship.`,
        tretjiOdstavek: `We are proud of our experienced team and our machines that ensure the manufacture and processing of welded machine parts.`,
        cetrtiOdstavek: `We offer welding, grinding, painting, milling and turning.`,
        petiOdstavek: `In our company We manufacture various machine parts, for which quality welding and mechanical processing are very important.`,
        sestiOdstavek: `We weld different materials with different welding processes. When performing welding work, we perform initial, intermediate and final NDT inspection.`,
        sedmiOdstavek: `To ensure quality, we check welds, control dimensions (tolerance) and control surface quality.`,
        osmiOdstavek: `We ensure the highest possible compatibility and reliability when using products in the most demanding working conditions.`,
        devetiOdstavek: `We currently have 6 horizontal milling and boring machines, 2 milling machines and 2 turning machines. On our largest CNC horizontal milling and boring machine (x: 4000, y: 2500) we can machine up to 6 tons of heavy workpieces. We turn parts up to 780mm in diameter and 3000mm in length.`,
        dimenzije: {
          bohrwerk: ['Table dimensions : 2000 × 2000', 'X : 4000, Y : 2800, Z : 4000', 'B : 360 degrees', 'Spindle : Ø 130', 'Clamping ISO : 50', 'Maximum load : 50 tons'],
          struznica: ['Diameter : 780 mm', 'Turning length : 3000 mm', 'Turning diameter : 780 mm', 'Spindle diameter: 100 mm']
        },
        financiraEU: {
          naslov: 'Project: Investment in high-tech CNC metal forming equipment',
          podnaslov: 'JR P4I 2022 - Incentives for co-financing initial investments in less developed areas',
          namen: 'Purpose of the project:',
          namenOpis: 'The object of the investment is the purchase of a CNC metal forming machine VF-4SS and a CNC lathe ST-30. The investment will enable greater material efficiency, reduction of energy consumption and reduction of waste in production.',
          rezultatiText: 'Planned results:',
          rezultati: ['improve the material efficiency of production', 'reduction of energy consumption in production', 'reduction of waste in production', 'increase in added value per employee', '1 new job in the company', 'improving product quality'],
          zakljucek: 'Project completion:',
          visinaNalozbe: 'Amount of investment:',
          visinaNalozbeVsota: '190.600 EUR without VAT',
          VisinaFin: 'Amount of financing from the mechanism:',
          nalozbaJeDel: 'The investment is part of a plan financed by the mechanism',
          financira: 'Funded by the European Union – NextGenerationEU'
        }
      },
      german: {
        contact: {
          title: 'Kontakt',
          headquarters: 'Hauptquartier: ',
          branch: 'Ast: '
        },
        certifikatNaslov: `Unsere Zertifikate`,
        strojiNaslov: `Unsere Maschinen`,
        prviOdstavek: `Das Familienunternehmen SADS D.O.O. befindet sich in der Handwerkszone in Radlje ob Dravi. Innovation und Qualität sind die treibende Kraft unserer Aktivitäten und Teil aller Prozesse in unserem Unternehmen.`,
        drugiOdstavek: `Wir beschäftigen uns mit dem Schweißen und der mechanischen Bearbeitung von Metallen. Wir sind präzise, ​​innovativ und anpassungsfähig an unsere Kunden. Unsere Richtlinie lautet: Qualitätsverarbeitung.`,
        tretjiOdstavek: `Wir sind stolz auf unser erfahrenes team und unser Maschinen, die die Herstellung und Verarbeitung von geschweißten Maschinenteilen sicherstellen.`,
        cetrtiOdstavek: `Wir bieten Schweißen, Schleifen, Lackieren, Fräsen und Drehen an.`,
        petiOdstavek: `In unserer Firma stellen wir verschiedene Maschinenteile her, für die Qualitätsschweißen und mechanische Bearbeitung sehr wichtig sind.`,
        sestiOdstavek: `Wir schweißen verschiedenes Material mit unterschiedlichen Schweißverfahren. Bei der Ausführung der Schweißarbeiten führen wir eine Anfangs-, eine Zwischen- und eine End-NDT-Kontrolle durch.`,
        sedmiOdstavek: `Um die Qualität sicherzustellen, prüfen wir Schweißnähte, kontrollieren die Abmessungen (Toleranz) und kontrollieren die Oberflächenqualität. `,
        osmiOdstavek: `Wir gewährleisten die höchstmögliche Kompatibilität und Zuverlässigkeit bei der Verwendung von Produkten unter anspruchsvollsten Arbeitsbedingungen.`,
        devetiOdstavek: `Wir haben derzeit 6 Horizontale Bohr und Fräsmachinen, 2 Fräsmaschinen und 2 Drehmaschinen. Auf unserer größten CNC Horizontale Bohr und Fräsmachine (x: 4000, y: 2500) können wir bis zu 6 Tonnen schwere Werkstücke bearbeiten. Wir drehen Teile mit einem Durchmesser von bis zu 780 mm und einer Länge von 3000 mm.`,
        dimenzije: {
          bohrwerk: ['Tischdimension : 2000 × 2000', 'X : 4000, Y : 2800, Z : 4000', 'B : 360 grad', 'Spindel : Ø 130', 'Einspannung ISO : 50', 'Maximale Belastung : 50 Tonnen'],
          struznica: ['Durchmesser : 780 mm', 'Drehlange : 3000 mm', 'Drehdurchmesser : 500 mm', 'Spindel durchmesser: 100 mm']
        },
        financiraEU: {
          naslov: 'Projekt: Investition in Hightech-CNC-Umformmaschinen',
          podnaslov: 'JR P4I 2022 – Anreize für die Kofinanzierung von Erstinvestitionen in weniger entwickelten Gebieten',
          namen: 'Zweck des Projekts:',
          namenOpis: 'Gegenstand der Investition ist der Kauf einer CNC-Umformmaschine VF-4SS und einer CNC-Drehmaschine ST-30. Die Investition wird eine höhere Materialeffizienz, eine Reduzierung des Energieverbrauchs und eine Reduzierung des Abfalls in der Produktion ermöglichen.',
          rezultatiText: 'Geplante Ergebnisse:',
          rezultati: ['Verbesserung der Materialeffizienz der Produktion', 'Reduzierung des Energieverbrauchs in der Produktion', 'Reduzierung von Abfall in der Produktion', 'Steigerung der Wertschöpfung pro Mitarbeiter', '1 neue Stelle im Unternehmen', 'Verbesserung der Produktqualität'],
          zakljucek: 'Projektabschluss:',
          visinaNalozbe: 'Höhe der Investition:',
          visinaNalozbeVsota: '190.600 EUR ohne MwSt',
          VisinaFin: 'Finanzierungsbetrag aus dem Mechanismus:',
          nalozbaJeDel: 'Die Investition ist Teil eines Plans, der durch den Mechanismus finanziert wird',
          financira: 'Gefördert von der Europäischen Union – NextGenerationEU'
        }
      }
    }
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn
    },
    error(state) {
      return state.error
    },
    getLanguage(state) {
      return state.language
    },
    getDescription(state) {
      if (state.language === 'SLO') {
        return state.description.slovenian
      } else if (state.language === 'ENG') {
        return state.description.english
      } else {
        return state.description.german
      }
    }
  },
  mutations: {
    checkLoggedIn(state, payload) {
      state.loggedIn = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    changeLanguage(state,payload) {
      state.language = payload
    }
  },
  actions: {
    
  },
  modules: {
  }
})
