<template>
    <b-col id="carousel-1" cols="12" lg="8">
        <b-carousel
            v-model="slide"
            :interval="4000"
            controls
            indicators
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
        >
            <b-carousel-slide class="image-carousel">
                <template v-slot:img>
                    <img 
                        :src="require('../../assets/1Carousel.jpg')"
                        class="d-block img-fluid w-100"
                        alt="Turning machine operated by SADS employee"    
                    >
                </template>
            </b-carousel-slide>
            <b-carousel-slide class="image-carousel">
                <template v-slot:img>
                    <img 
                        :src="require('../../assets/2Carousel.jpg')"
                        class="d-block img-fluid w-100"
                        alt="Horizontal milling and boring machine"    
                    >
                </template>
            </b-carousel-slide>
            <b-carousel-slide class="image-carousel">
                <template v-slot:img>
                    <img 
                        :src="require('../../assets/3Carousel.jpg')"
                        class="d-block img-fluid w-100"
                        alt="Turning machine working on a product"    
                    >
                </template>
            </b-carousel-slide>
            <b-carousel-slide class="image-carousel">
                <template v-slot:img>
                    <img 
                        :src="require('../../assets/4Carousel.jpg')"
                        class="d-block img-fluid w-100"
                        alt="Horizontal milling and boring machine with product on it"    
                    >
                </template>
            </b-carousel-slide>
        </b-carousel>
    </b-col>
</template>

<script>
export default {
  name: 'Carousel',
  data () {
      return {
          slide: 0,
          sliding: null
      }
  },
  methods: {
    onSlideStart(slide) {
        this.sliding = true
    },
    onSlideEnd(slide) {
        this.sliding = false
    }
  }
}
</script>

<style>
#carousel-1{
  padding: 0;
  border-top: 1px solid #0055a6;
  border-bottom: 1px solid #0055a6;
  overflow: hidden;
}
</style>