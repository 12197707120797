<template>
  <b-col cols="12" lg="4" class="description">
    <h1>SADS d.o.o. <span id="added-title">Rezkanje, Struženje</span></h1>
    <p class="my-5">
        {{ descriptionText.prviOdstavek }}
    </p>
    <p class="my-5">{{ descriptionText.drugiOdstavek }}</p>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Description',
  data() {
    return {
      descriptionText: {}
    }
  },
  created() {
    this.getDescription()
  },
  computed: mapState(['language']),
  watch: {
    language(val) {
      this.getDescription()
    }
  },
  methods: {
    getDescription() {
      this.descriptionText = this.$store.getters.getDescription
    }
  }
}
</script>

<style>
#added-title{
  display: none;
}
</style>
